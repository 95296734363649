<template>
  <div class="login">
    <div class="info" v-if="type == 1">
      <img src="../assets/newImg/login1.png" style="margin: 80px 0" />
      <img src="../assets/newImg/login2.png" />

      <div>
        <div style="width: 100%; height: 30px"></div>

        <div class="line">
          <h3>{{ $t("info.lang.account") }}</h3>
          <div class="input">
            <el-input v-model="account" placeholder="请输入手机号"></el-input>
          </div>
        </div>
        <div class="line">
          <h3>验证码</h3>
          <div class="input">
            <el-input v-model="code" placeholder="请输入验证码"></el-input>
            <el-button type="primary" v-if="time == 0"
              ><p class="font" @click="timeOrder">获取验证码</p></el-button
            >
            <el-button type="info" v-else
              ><p class="font">{{ time }}s</p></el-button
            >
          </div>
        </div>

        <div class="button">
          <el-button type="primary" @click="register" style="width: 100%"
            >绑定手机号</el-button
          >
        </div>

        <div class="line1">
          <p @click="$router.push({ name: 'login' })">返回</p>
        </div>
      </div>
    </div>

    <div
      class="info"
      v-if="type == 2"
      v-loading="true"
      element-loading-text="正在微信登录"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    ></div>
    <img src="../assets/newImg/banner.jpg" class="pic" />
  </div>
</template>

<script>
import { bindMobile, login, verify, sendCode, miniappLogin } from "../assets/api/index";

export default {
  data() {
    return {
      type: "",
      account: "",
      psw: "",
      nickname: "",
      nickname1: "",
      time: 0,
      code: "",
      lang: "zh",
      activeName: "login1",
      type1: "login",
      timmer: "",
      type: "",
      openid: "",
    };
  },
  methods: {
    timeOrder() {
      if (!this.account) {
        this.$message.error(this.$t("info.lang.meetingError13")!="info.lang.meetingError13"?this.$t("info.lang.meetingError13"):"手机号不能为空");
        return;
      }

      var data = {
        mobile: this.account,
      };

      sendCode(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success(this.$t("info.lang.alertMsg10")!="info.lang.alertMsg10"?this.$t("info.lang.alertMsg10"):"验证码已发送");
        } else {
          this.$message.error(this.$t("info.lang.meetingError32")!="info.lang.meetingError32"?this.$t("info.lang.meetingError32"):"验证码发送失败");
        }
      });
      this.time = 30;

      this.timmer = setInterval(() => {
        this.time--;
        if (this.time == 0) {
          clearInterval(this.timmer);
        }
      }, 1000);
    },

    register() {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!this.account || !this.code) {
        this.$message.error(this.$t("info.lang.meetingError26")!="info.lang.meetingError26"?this.$t("info.lang.meetingError26"):"您还有未填项");
        return;
      }

      if (!reg.test(this.account)) {
        this.$message.error(this.$t("info.lang.meetingError31")!="info.lang.meetingError31"?this.$t("info.lang.meetingError31"):"请输入正确的手机号");
        return;
      }

      var data = {
        mobile: this.account,
        code: this.code,
      };
      verify(data).then((res) => {
        console.log(res);

        if (res.code == 0) {
          bindMobile({
            acount: this.account,
            code: this.code,
            openid: this.openid,
            name: this.name,
            headimgurl: this.headimgurl,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t("info.lang.alertMsg11")!="info.lang.alertMsg11"?this.$t("info.lang.alertMsg11"):"注册成功");
              var info = JSON.stringify({ token: res.data.token, type: "user" });
              localStorage.setItem("userInfo", info);
              var width = document.documentElement.clientWidth;
              if (width < 1000) {
                this.$router.push({ name: "mobileIndex" });
              } else {
                this.$router.push({ name: "index" });
              }
            } else {
              this.$message.error(res.info);
            }
          });
        } else {
          this.$message.error(this.$t("info.lang.meetingError33")!="info.lang.meetingError33"?this.$t("info.lang.meetingError33"):"验证码输入错误");
        }
      });
    },

    getRandomSixNum() {
      let RandomSixStr = "";
      for (let i = 0; i < 6; i++) {
        RandomSixStr += String(Math.floor(Math.random() * 10));
      }
      return RandomSixStr;
    },
  },
  watch: {
    activeName(val) {
      if (val == "visitor") {
        this.vnum = this.getRandomSixNum();
        this.nickname1 = `ゲスト${this.vnum}`;
      }
    },
  },

  beforeCreate() {},

  created() {},

  mounted() {
    console.log(this.$route.query);
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.openid = this.$route.query.openid;
      this.name = this.$route.query.name;
      this.headimgurl = this.$route.query.headimgurl;
      console.log(this.$router.query);
    } else if (this.type == 2) {
      this.account = this.$route.query.mobile;
      this.psw = this.$route.query.psd;
      login({
        acount: this.account,
        psd: this.psw,
      }).then((res) => {
        var info = JSON.stringify({ token: res.data.token, type: "user" });
        localStorage.setItem("userInfo", info);
        var width = document.documentElement.clientWidth;
        if (width < 1000) {
          this.$router.push({ name: "mobileIndex" });
        } else {
          this.$router.push({ name: "index" });
        }
      });
    } else if (this.type == 3) {
      this.openid = this.$route.query.id;
      this.acount = this.$route.query.phone;
      miniappLogin({
        openid: this.openid,
        acount: this.acount,
      }).then((res) => {
        var info = JSON.stringify({ token: res.data.token, type: "user" });
        localStorage.setItem("userInfo", info);
        this.$router.push({ name: "mobileIndex" });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;

  .info {
    width: 367px;
    height: 100%;
    background: rgba($color: #0e0e0e, $alpha: 0.8);
    box-shadow: 3.94px 4.53px 21px 6px rgba(255, 43, 43, 0.1);
    padding: 0 95px;
    h1 {
      padding-top: 60px;
      font-size: 26px;
      font-weight: 800;
      color: white;
      margin-bottom: 44px;
    }
    .button {
      text-align: left;
      margin-top: 35px;
    }
    .lang {
      display: flex;
      position: fixed;
      bottom: 30px;
      align-items: center;
      color: white;
      right: 30px;
      ::v-deep .el-input__inner {
        background: transparent;
        color: white;
      }
    }

    .login3 {
      display: flex;
      position: fixed;
      bottom: 30px;
      align-items: center;
      color: white;
    }

    ::v-deep .el-tabs__item.is-active {
      color: #ff0000;
    }
    ::v-deep .el-tabs__item {
      color: white;
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #ff0000;
    }
    .chooseType {
      .el-button {
        font-size: 20px;
        width: 70%;
        margin-bottom: 30px;
      }
      .el-button--danger {
        background-color: #ff0000;
        border-color: #ff0000;
      }
    }
  }
  .pic {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .el-button--primary {
    background-color: #ff0000;
    border-color: #ff0000;
    width: 48%;
  }

  .el-button--info {
    width: 48%;
  }
  .input1 {
    width: 49%;
    display: inline-block;
  }
  .line1 {
    margin-top: 20px;
    color: white;
    justify-content: space-around;
    display: flex;
    p {
      font-size: 12px;
      cursor: pointer;
    }
    p:hover {
      color: #ff0000;
    }
  }

  .line2 {
    justify-content: center;
    display: flex;
    color: #828282;
    font-size: 12px;
    margin-top: 30px;
  }
  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    h3 {
      font-size: 20px;
      color: white;
      text-align: left;
      margin-right: 12px;
      width: 100px;
    }
    .input {
      position: relative;
      flex: 1;

      input {
        background: transparent;
        -webkit-appearance: none;

        background-image: none;
        border-radius: 4px;
        border: 1px solid #1a1a1a;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        font-size: 12px;
      }

      .el-button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .toEmail {
    text-align: center;
    font-size: 14px;

    font-weight: 400;
    margin-top: 10px;
    color: #828282;
    cursor: pointer;
  }
}
a {
  text-decoration: none;
}

.font {
  width: 85px;
}

.wx {
  width: 50px;
  margin-top: 20px;
  cursor: pointer;
}

#wx {
  width: 300px;
  height: 300px;
  background: white;
  margin-left: 40px;
}
@media screen and (max-width: 1000px) {
  .login {
    .info {
      transform: scale(0.6);
      margin-top: -105px;
      margin-left: -130px;
    }
  }
}
</style>
