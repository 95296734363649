<template>
    <div>
        <div class="pwdForm">
            <div class="showBox">
                <h3>{{ $t("info.lang.confirmPassword") }}</h3>
                <div class="input">
                    <el-input v-model="inputPwd" placeholder="パスワードを入力してください。" @focus.stop="keyFlag(true)"
                        @blur="keyFlag(false)" ref="input" @click.stop=""></el-input>
                </div>
                <div class="button">
                    <div class="bn1" @click="closeClick(false)">{{ $t("info.lang.setCancel") }}</div>
                    <div class="bn2" @click="confirmPwd()">{{ $t("info.lang.setOK") }}</div>
                </div>
                <!-- <img class="avatar1" src="../assets/role/qr.png" style="top: 205px" /> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        p_roomPwd: {
            type: String
        }
    },
    data() {
        return {
            inputPwd: "",
            meetingPwd: "",
            pwdErrFlg: true
        }
    },
    methods: {
        closeClick(e) {
            this.Bus.$emit("onClosePwd", e);
        },
        keyFlag(e) {
            this.Bus.$emit("keyFlag", e);
        },
        confirmPwd() {
            if (this.inputPwd == this.meetingPwd) {
                this.pwdErrFlg = false;
                this.Bus.$emit("onConfirmPwd", this.pwdErrFlg);
            } else {
                this.$message.error(this.$t("info.lang.pwdCheckError"));
                this.Bus.$emit("onConfirmPwd", this.pwdErrFlg);
            }
        }
    },
    created() {
        this.meetingPwd = this.p_roomPwd;
    }
}
</script>

<style lang="scss" scoped>
.showBox {
    background-color: rgb(38, 38, 38);
    width: 258px;
    height: 200px;
    padding: 0 15px;
    background: #262626;
    border-radius: 6px;
    position: fixed;
    // left: 20px;
    // top: 75px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;

    h3 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 26px;
        border-bottom: 1px solid #191919;
        color: white;
        padding: 10px 0;
    }

    .avatar1 {
        width: 80px;
        height: 80px;

        background: #fff;

        border-radius: 50%;
        cursor: pointer;
        margin-right: 15px;
    }

    p {
        width: 70px;
        color: white;
        margin-left: 5px;
        font-size: 14px;
    }
}

.input {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 17px;
}

.button {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.bn1 {
    width: 80px;
    height: 27px;
    background: #262626;
    border: 1px solid #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 25px;
    font-size: 12px;
}

.bn2 {
    width: 80px;
    height: 27px;
    background: #ff0000;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
</style>