<template>
  <div>
    <audio :src="src" id="start" loop></audio>
    <audio :src="src1" id="bgm"></audio>

    <img src="../assets/mobile/loginOut.png" class="loginout" @click="logout" />
    <vcircle v-if="yaogan" />
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="tishi" v-if="show">
        <img
          src="../assets/newImg/bottomImg.png"
          class="bottomImg"
          @click="show1 = true"
        />
        <transition enter-active-class="animated fadeIn">
          <div class="msg1" v-if="show1 && build" @click="play()">
            <p>
              你好！我是梦界空间AI导览员宇航兔。接下来我将带领大家参观整个梦界空间，有什么问题都可以问我哦！
            </p>
            <img class="close" @click="show1 = false" src="../assets/newImg/close.png" />
          </div>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <div class="msg" v-if="show1 && !build">
            <h4>{{ title }}</h4>
            <p>{{ msg }}</p>
            <img class="close" @click="show1 = false" src="../assets/newImg/close.png" />

            <div class="jinru" @click="join1(title)">进入</div>

            <div class="fanhui" @click="back">返回鸟瞰</div>
          </div>
        </transition>
      </div>
    </transition>

    <transition
      enter-active-class="animated fadeInLeft"
      leave-active-class="animated fadeOutLeft"
    >
      <div class="toast" v-if="toast">
        该建筑正在建设中，敬请期待！
        <img
          class="close"
          @click="toast = false"
          src="../assets/newImg/close.png"
          style="margin-top: 2px"
        />
      </div>
    </transition>

    <transition
      enter-active-class="animated fadeInLeft"
      leave-active-class="animated fadeOutLeft"
    >
      <div class="caozuo" v-if="tishi">
        <div class="caozuoButton" @click="tishi = false">我知道了</div>
      </div>
    </transition>

    <transition
      enter-active-class="animated fadeInLeft"
      leave-active-class="animated fadeOutLeft"
    >
      <div class="caozuo1" v-if="tishi1">
        <div
          class="caozuoButton"
          @click="
            tishi1 = false;
            show = true;
          "
        >
          我知道了
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vcircle from "./circle.vue";
export default {
  data() {
    return {
      step: 0,
      translate: 0,
      timmer: "",
      show: false,
      show1: true,
      build: true,
      value: 0,
      tishi1: true,
      yaogan: false,
      placeList: [
        {
          pos: [-394987, -222996, 599979, -681561, 130556, 807043],
          msg:
            "打造西部地区首屈一指的直播产业基地，培育和完善直播电商产业链，丰富我区数字经济产业业态。",
          title: "直播电商基地",
          id: 11,

          src: require("../assets/audio/zhibo.mp3"),
        },

        {
          pos: [-367068, -351069, 348571, -715154, 143905, 700475],
          id: 12,
          title: "先进计算创新中心",
          msg:
            "总投资约40亿元,高性能图形处理器研发及产业化全球总部项目（GPU项目）打破国外垄断，实现新一代高性能GPU的大规模产业化",

          src: require("../assets/audio/xianjin.mp3"),
        },
        {
          pos: [97134, -197708, 188526, 557051, 297508, 370835],
          id: "06",
          title: "水乐园",
          msg:
            "动漫主题水世界，定制动漫IP融合室内、室外体验项目的全天候水上游乐场，全方位游乐体验、动漫主题展览、互动活动。",
          src: require("../assets/audio/shuileyuan.mp3"),
        },

        {
          id: 10,
          title: "进化光环",
          pos: [-119792, -206665, 512733, 225181, 280981, 569246],
          msg:
            "规划用地约236亩 ，总建筑面积约8.6万平方米，集动漫公园、数字化展厅、数字影视基地、LIVE HOUSE、沐光森林植物园等为一体的多功能综合展馆，构建数字化服务平台，打造动漫文化综合体、科普文化体验中心。",
          src: require("../assets/audio/jinhua.mp3"),
        },
        {
          id: "01",
          title: "动漫公园",
          pos: [-183511, -228285, 66372, 294505, 266689, 194825],
          msg: "以数字交互为基础，打造璧山特色IP动漫公园",

          src: require("../assets/audio/dongman.mp3"),
        },

        {
          id: "07",
          title: "中新科技大厦",
          pos: [49445, -8426, -89011, -296612, 345126, -161425],
          msg: "集成管理办公、企业孵化、会议展览等功能为一体的综合办公空间",
          src: require("../assets/audio/zhongxin.mp3"),
        },
        {
          id: 14,
          title: "温泉度假酒店",
          pos: [-60589, -60641, -175658, -413942, 292911, -187562],
          msg:
            "五星级标准建设，规划面积约20000㎡，集温泉疗养、主题客房、养生餐饮、商务会所、休闲健身等功能于一体",
          src: require("../assets/audio/wenquan.mp3"),
        },

        {
          id: "02",
          title: "潮流天街",
          pos: [350363, -222183, -372057, 488665, 272791, -847318],
          msg:
            "聚集赛博朋克风的集创意工坊、沉浸式互动娱乐综合店、跨界书店、璧山主题餐吧等新业态",
          src: require("../assets/audio/chaoliu.mp3"),
        },

        {
          id: "09",
          title: "未来战争馆",
          pos: [813867, -110891, -373667, 461971, 242661, -407857],
          msg: "未来战态体系的展示与体验，未来空间作战、海洋作战、网络作战、城市作战。",
          src: require("../assets/audio/weilai.mp3"),
        },

        {
          id: "05",
          title: "时光胶囊",
          pos: [634962, 21460, 432247, 514303, 375014, 764574],
          msg:
            "规划总长约3公里，总投资约6亿元，国内首条高速磁悬浮文旅线，VR+沉浸式穿越体验。",
          src: require("../assets/audio/shiguang.mp3"),
        },

        {
          id: "04",
          title: "元宇宙创新中心",
          pos: [109670, -70944, 658876, 80253, 283501, 1010306],
          msg:
            "以元宇宙XR生态及数字内容创制为重点领域的产业创智中心，凝聚海内外头部企业及本土创新创意团队，聚焦从元宇宙数字内容创制、到技术创新应用、到商业化发行的完整闭环，以场景带动产业，以产业赋能生态，以生态凝聚人才，驱动璧山元宇宙数字新经济的蓬勃发展 ",
          src: require("../assets/audio/yuanyuzhou.mp3"),
        },

        {
          id: 13,
          title: "易平方网络科技运营总部",
          pos: [-173789, -454090, -164958, -829932, 202484, 141149],
          msg:
            "国内领先的物联网时代跨屏跨场景文娱系统服务商，打造“互联网+跨场景生态”网络营销新平台。",
          src: require("../assets/audio/yipingfang.mp3"),
        },

        {
          id: "03",
          title: "数字经济产业园",
          pos: [-767611, 20807, -133861, -698889, 515782, -624042],
          msg:
            "由康佳集团集团打造，包含数字创新区、数字创意区、数字总部区等三大区域，涉及智能IOT设备、数字设计协同创新、数字创新产业、数字创意文化集市、数据金融结算中心等内容。",

          src: require("../assets/audio/shuju.mp3"),
        },

        {
          id: 15,
          title: "绣娘基地",
          pos: [-512754, 123609, -417534, -974938, 618584, -240374],
          msg:
            "深山绣娘非遗国潮聚集地，刺绣主题服装秀，大型城市宣传方案，国潮网红培育基地，打造中国手工艺特色产业园和手工艺教育传习基地，以低密度的建筑形态打造手工艺人的个人工作室、传帮带教室、大师精品展等空间。",

          src: require("../assets/audio/xiuniang.mp3"),
        },

        {
          id: "08",
          title: "太空天梯",
          pos: [340004, -287908, -189443, 915025, 319790, 7151],
          msg:
            "融合未来空间科学研究、太空天梯实验和公众娱乐体验三种功能于一体的多段式可重构多功能系统，模拟真实升空体验。",

          src: require("../assets/audio/taikong.mp3"),
        },
      ],
      msg: "",
      title: "",
      toast: false,
      tishi: false,
      src: require("../assets/audio/start.mp3"),
      src1: require("../assets/audio/shapan.wav"),

      yaogan: false,
    };
  },
  components: {
    vcircle,
  },
  methods: {
    logout() {
      localStorage.removeItem("info");
      location.reload();
    },
    play() {
      this.src = require("../assets/audio/start.mp3");
      document.getElementById("start").play();
    },
    back() {
      document.o2.switch_camera(-2049, -246449, 344017, -841373, 602079, 219380);
      document.o2.search_obj_by_name("icon").visible = true;
      document.getElementById("start").pause();
      this.build = true;
      this.toast = false;
    },
    join1(title) {
      console.log(title);
      if (title == "进化光环") {
        document.o2.fixed_view = false;
        document.o2.setRolePos(55567, 9800, 541986);
        document.o2.cameraControls.maxDistance = 5000;
        document.o2.cameraControls.minDistance = 100;
        document.o2.cameraControls.maxPolarAngle = Math.PI * 0.65;
        document.o2.cameraControls.minPolarAngle = Math.PI / 10;
        document.o2.camera.updateProjectionMatrix();
        this.show = false;

        this.tishi = true;
        var width = document.documentElement.clientWidth;

        if (width < 1000) {
          this.yaogan = true;
        }
        document.getElementById("start").pause();
        this.src1 = require("../assets/audio/fujin.wav");

        setTimeout(() => {
          document.getElementById("bgm").play();
        }, 500);
      } else {
        this.toast = true;
      }
      // console.log("123123");
      // localStorage.setItem("dreamType", 1);
      // location.reload();
      // done();
    },
  },
  mounted() {
    document.addEventListener("click", function () {
      if (document.getElementById("bgm")) {
        document.getElementById("bgm").play();
      }
    });
    this.Bus.$on("梦界空间点击", (e) => {
      var i = e.split("_");
      var name = i[0];
      if (name == "icon") {
        this.value = i[1];
        console.log(this.value);
        var item = this.placeList.find((x) => x.id == this.value);

        this.msg = item.msg;
        this.title = item.title;
        document.o2.switch_camera(
          item.pos[0],
          item.pos[1],
          item.pos[2],
          item.pos[3],
          item.pos[4],
          item.pos[5]
        );

        this.build = false;
        this.show1 = true;
        document.o2.search_obj_by_name("icon").visible = false;
        this.src = item.src;
        setTimeout(() => {
          document.getElementById("start").play();
        }, 500);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.loginout {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.tishi {
  position: fixed;
  left: 0;
  bottom: 30px;
  display: flex;

  align-items: center;
  left: 80px;
  bottom: 60px;
  height: 250px;
}
.bottomImg {
  margin-right: 40px;
  width: 106px;
}

.msg {
  background: url("../assets/newImg/duihua.png");
  width: 539px;
  height: 234px;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  color: #fff;
  position: relative;

  h4 {
    font-size: 21px;
    margin: 40px 0 0 20px;
  }

  p {
    font-size: 16px;
    width: 487px;
    height: 100px;
    overflow-y: scroll;
    margin-top: 17px;
    margin-left: 20px;
  }

  p::-webkit-scrollbar {
    display: none;
  }
}

.msg1 {
  background: url("../assets/newImg/duihua1.png");
  width: 539px;
  height: 125px;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  position: relative;
  p {
    margin-top: 40px;
    margin-left: 20px;
    color: #ffffff;
    width: 487px;
  }
}
.msg::-webkit-scrollbar {
  display: none;
}

.showBox {
  width: 1156px;
  height: 302px;
  background: url("../assets/newImg/showbox.png");
  position: fixed;
  left: 0;
}

.close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 11px;
  top: 10px;
  cursor: pointer;
  color: #ffffff;
}

.jinru,
.fanhui {
  width: 112px;
  height: 39px;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jinru {
  background: url("../assets/newImg/jinru.png");
  left: 269px;
  bottom: 18px;
}

.fanhui {
  background: url("../assets/newImg/fanhui.png");
  left: 395px;
  bottom: 18px;
  color: #666;
}

.toast {
  width: 402px;
  height: 120px;
  background: url("../assets/newImg/toast.png");
  font-size: 22px;
  color: #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.caozuo,
.caozuo1 {
  width: 418px;
  height: 361px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: url("../assets/newImg/caozuo1.png");

  .caozuoButton {
    width: 107px;
    height: 45px;
    background: #1f2c3a;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }
}

.caozuo1 {
  background: url("../assets/newImg/caozuo0.png");
  background-size: 100% 100%;
  width: 455px;
}

@media screen and (max-width: 1000px) {
  .loginout {
    transform: scale(0.5);
    opacity: 0.6;
    right: -25px;
    top: -0px;
  }
  .bottomImg {
    margin-right: 10px;
    width: 66px;
  }

  .msg1 {
    width: 422.33px;
    height: 90px;
    background-size: 100% 100%;

    p {
      width: 379px;
      height: 28px;
      font-size: 12px;
      margin-top: 25px;
    }
  }

  .close {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 9px;
    top: 8px;
  }

  .tishi {
    height: 175px;
    width: 498px;
    left: -36px;
    /* right: 0; */
    /* margin: auto; */
    transform: scale(0.7);
    bottom: -17px;
  }

  .msg {
    background-size: 100% 100%;
    width: 422.33px;
    height: 175px;

    h4 {
      font-size: 16px;
      margin: 25px 0 0 20px;
    }

    p {
      font-size: 12px;

      width: 376.35px;
      height: 75px;
      margin-top: 15px;
      text-align: justify;
    }

    .jinru,
    .fanhui {
      transform: scale(0.7);
      bottom: 5px;
    }

    .jinru {
      left: 230px;
    }
    .fanhui {
      left: 313px;
    }
  }

  .toast {
    width: 300px;
    height: 92px;
    background-size: 100% 100%;
    font-size: 16px;
    top: -150px;
  }
  .caozuo1 {
    background: url("../assets/newImg/caozuo3.png");
    width: 264.7px;
    height: 232.51px;
    background-size: 100% 100%;
    .caozuoButton {
      transform: scale(0.7);
      right: 72px;
    }
  }

  .caozuo {
    background: url("../assets/newImg/caozuo4.png");
    width: 264.7px;
    height: 232.51px;
    background-size: 100% 100%;
    .caozuoButton {
      transform: scale(0.7);
      right: 72px;
    }
  }
}
</style>
