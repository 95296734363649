<template>
  <div>
    <div class="cFunction" v-if="auth > 2 && allowShare">
      <div>
        <img :src="!yuyinFlag
          ? require('../assets/newImg/function/yuyin.png')
          : require('../assets/newImg/function/yuyinGreen.png')
          " @click="open(1)" :class="yuyinFlag ? 'color' : ''" />

        <p style="color: white; font-size: 12px">{{ $t("info.lang.functionMike") }}</p>
      </div>
      <div>
        <img :src="!shipinFlag
          ? require('../assets/newImg/function/xiangji.png')
          : require('../assets/newImg/function/xiangjiGreen.png')
          " @click="open(2)" :class="shipinFlag ? 'color' : ''" />

        <p style="color: white; font-size: 12px">{{ $t("info.lang.functionVideo") }}</p>
      </div>
      <div>
        <img :src="!screenFlag
          ? require('../assets/newImg/function/screen.png')
          : require('../assets/newImg/function/screenGreen.png')
          " @click="open(3)" :class="screenFlag ? 'color' : ''" />

        <p style="color: white; font-size: 12px">{{ $t("info.lang.functionShare") }}</p>
      </div>
    </div>
    <img src="../assets/newImg/mobile/ss.png" class="ss" @click="flag = !flag"
      :style="flag ? 'right:300px' : 'right:0px'" v-if="allowShare" />
    <audio id="chime_audio"></audio>
    <transition enter-active-class="animated fadeInRight" leave-active-class="animated fadeOutRight">
      <div class="shadow" v-show="flag" id="shadow">
        <div id="shareScreen" :class="playValue == -1 ? 'videoBox pos' : 'videoBox'" v-if="shareFlag"
          @click="playValue = -1">
          <img src="../assets/newImg/function/background.png" class="img" />
          <video id="shareScreen_chime_video"
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 99"></video>
          <img src="../assets/newImg/function/close.png" class="close" v-if="playValue == -1"
            @click.stop="playValue = -2" />
          <div class="msg">
            <p>{{ sharename }}は画面を共有しています</p>
          </div>
        </div>
        <div :id="item.userId" :class="playValue == item.userId ? 'videoBox pos' : 'videoBox'"
          v-for="(item, index) in list" :key="item.userId" @click="playValue = item.userId">
          <img src="../assets/newImg/function/background.png" class="img" />
          <video :id="item.userId + '_chime_video'"
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 99"></video>
          <img src="../assets/newImg/function/close.png" class="close" v-if="playValue == item.userId"
            @click.stop="playValue = -2" />
          <div class="msg">
            <div class="pic" v-if="!item.hasVideo">
              {{ $chat.searchName(item.userId) }}
            </div>
            <p>{{ $chat.searchName(item.userId) }}</p>
            <div class="iconList">
              <img v-if="!item.hasAudio" src="../assets/newImg/function/yuyinClose.png" />
              <img v-else :src="!item.audioMuted
                ? require('../assets/newImg/function/yuyinOpen.png')
                : require('../assets/newImg/function/yuyinClose.png')
                " />

              <img src="../assets/newImg/function/xiangjiClose.png" v-if="!item.hasVideo" />
              <img v-else :src="!item.videoMuted
                ? require('../assets/newImg/function/xiangjiOpen.png')
                : require('../assets/newImg/function/xiangjiClose.png')
                " />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { client } from "../assets/api/index";
export default {
  data() {
    return {
      flag: false,
      list: [],
      yuyinFlag: false,
      shipinFlag: false,
      screenFlag: false,
      playValue: -2,
      shareFlag: false,
      sharename: "",
      qpFlag: false,
      localFlag: false,
      auth: 0,
      allowShare: false,
    };
  },
  methods: {
    open(e) {
      console.log("@@@@@",this.$client);
      // if (!this.$client.clientFlag) {

      //   this.$message.error(this.$t("info.lang.clientError"));
      //   return;
      // }


      // if (e != 1 && !this.$client.cameraFlag) {

      //   this.$message.error("视频");
      //   return;
      // }

      // if (e == 1 && !this.$client.audioFlag) {

      //   this.$message.error("音频");
      //   return;
      // }

      console.log(this.$chat.role.auth);
      if (this.$chat.role.auth < 3) {
        this.$message.error(this.$t("info.lang.meetingError24") != "info.lang.meetingError24" ? this.$t("info.lang.meetingError24") : "您暂无权限");
        return;
      }
      if (e == 1) {
        if (
          this.$client.type == 1 &&
          !this.$client.typeFlag &&
          this.$chat.user_group != this.$client.roomId
        ) {
          this.$message.error(this.$t("info.lang.meetingError2") != "info.lang.meetingError2" ? this.$t("info.lang.meetingError2") : `虚拟直播厅已自动禁言,须举手向管理员申请`);
          return;
        }
        if (this.$chat.user_status[0] == 1) {
          this.$message.error(this.$t("info.lang.meetingError5" != "info.lang.meetingError5") ? this.$t("info.lang.meetingError5") : `您已被禁言`);
          return;
        }
        if (!document.client.micFlag) {
          this.$message.error(this.$t("info.lang.micError"));
          return;
        }
        this.yuyinFlag = !this.yuyinFlag;
        this.$client.muteAudio(this.yuyinFlag);
      } else if (e == 2) {
        if (this.$client.type == 1 && this.$chat.user_group != this.$client.roomId) {
          this.$message.error(this.$t("info.lang.meetingError4" != "info.lang.meetingError4") ? this.$t("info.lang.meetingError4") : `虚拟直播厅已自动禁止开启视频`);
          return;
        }
        if (this.$chat.user_status[1] == 1) {
          this.$message.error(this.$t("info.lang.meetingError8") != "info.lang.meetingError8" ? this.$t("info.lang.meetingError8") : `您已被禁止开启视频`);
          return;
        }
        if (!document.client.cameraFlag) {
          this.$message.error(this.$t("info.lang.videoError"));
          return;
        }
        this.shipinFlag = !this.shipinFlag;
        this.$client.muteVideo(this.shipinFlag);
      } else {
        if (this.$client.type == 1 && this.$chat.user_group != this.$client.roomId) {
          this.$message.error(this.$t("info.lang.meetingError3") != "info.lang.meetingError3" ? this.$t("info.lang.meetingError3") : `大会议室已自动禁止屏幕共享`);
          return;
        }
        if (this.$chat.user_status[3] == 1) {
          this.$message.error(this.$t("info.lang.meetingError7") != "info.lang.meetingError7" ? this.$t("info.lang.meetingError7") : `您已被禁止开启屏幕共享`);
          return;
        }
        if (this.$client.share && !this.screenFlag) {
          console.log(this.$client.share, !this.screenFlag);
          this.$message.error(this.$t("info.lang.shareError"));
          return;
        }
        this.screenFlag = !this.screenFlag;
        this.$client.shareScreen(this.screenFlag);
      }
    },
    check(e) {
      if (document.o2.meettingType == true) {
        this.$client.join(e);
        return;
      }
      var id = "";
      if (e == 5001) {
        id = 6;
      } else if (e == 5002) {
        id = 7;
      } else if (e == 5003) {
        id = 8;
      } else {
        var str = String(e);
        id = str.charAt(str.length - 1);
      }

      // https://o2vr.net/istation

      //https://api.wh12345.net/istation/

      client({ id: id }).then((res) => {
        var status = this.getStatus(res.data);
        if (status == 0 || status == 2) {
          this.$message.error(this.$t("info.lang.meetingError1") != "info.lang.meetingError1" ? this.$t("info.lang.meetingError1") : "会议室暂未开启");
        } else if (status == 3) {
          this.$message.error(this.$t("info.lang.meetingError1") != "info.lang.meetingError1" ? this.$t("info.lang.meetingError1") : "会议室已过期");
        } else {
          this.$prompt(this.$t("info.lang.alertMsg41") != "info.lang.alertMsg41" ? this.$t("info.lang.alertMsg41") : "请输入邀请码", "提示", {
            confirmButtonText: this.$t("info.lang.setOK") != "info.lang.setOK" ? this.$t("info.lang.setOK") : "确定",
            cancelButtonText: this.$t("info.lang.setCancel") != "info.lang.setCancel" ? this.$t("info.lang.setCancel") : "取消",
          })
            .then(({ value }) => {
              console.log(value);
              if (value == res.data.invite_code) {
                this.$message({
                  type: "success",
                  message: "正在加入房间",
                });
                this.$client.join(e);
              } else {
                this.$message.error(this.$t("info.lang.meetingError33") != "info.lang.meetingError33" ? this.$t("info.lang.meetingError33") : "验证码错误");
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: this.$t("info.lang.alertMsg22") != "info.lang.alertMsg22" ? this.$t("info.lang.alertMsg22") : "已取消",
              });
            });
        }
      });
    },
    getStatus(e) {
      if (e.time0 == null) {
        return 0; //空闲
      }
      var time = new Date().getTime();

      if (time > Number(e.time0) && time < Number(e.time1)) {
        return 1; //正在使用
      }

      if (time < e.time1) {
        return 2; //已预约
      }

      if (time > e.time1) {
        return 3;
        //已过期;
      }
    }
  },
  created() {
    this.Bus.$on("clientLogin", (e) => {
      if (this.$chat.user_status[2] == 1) {
        this.$message.error(this.$t("info.lang.meetingError6") != "info.lang.meetingError6" ? this.$t("info.lang.meetingError6") : `您已被禁止加入房间`);
        return;
      }

      if (
        e == 7005 ||
        e == 7004 ||
        e == 7003 ||
        e == 7002 ||
        e == 7001 ||
        e == 5001 ||
        e == 5002 ||
        e == 5003
      ) {
        if (this.flag) return;
        this.check(e);
        return;
      }

      if (e) {
        this.$client.join(e);
      }
    });

    this.Bus.$on("manage", (e) => {
      console.log(e);
      var manageList = e.split("-");
      var yuyinFlag = Number(manageList[0]);
      var shipinFlag = Number(manageList[1]);
      var roomFlag = Number(manageList[2]);
      var screenFlag = Number(manageList[3]);
      var authFlag = Number(manageList[4]);
      this.$chat.user_status = [yuyinFlag, shipinFlag, roomFlag, screenFlag, authFlag];

      this.$alert(
        `<div>语音权限:<span style="${yuyinFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${yuyinFlag == 1 ? "关闭" : "开启"}</span></div>
        <div>视频权限:<span style="${shipinFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${shipinFlag == 1 ? "关闭" : "开启"}</span></div>
        <div>进入房间权限:<span style="${roomFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${roomFlag == 1 ? "关闭" : "开启"}</span></div>
        <div>屏幕共享权限:<span style="${screenFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${screenFlag == 1 ? "关闭" : "开启"}</span></div>`,
        "当前您的权限已被管理员更改",
        {
          dangerouslyUseHTMLString: true,
        }
      );
      if (yuyinFlag == 1 && yuyinFlag) {
        console.log(123);
        this.yuyinFlag = false;
        this.$client.muteAudio(false);
      }

      if (shipinFlag == 1 && this.shipinFlag) {
        if (!this.$client.cameraFlag) {
          this.$message.error(this.$t("info.lang.videoError"));
          return;
        }
        this.shipinFlag = false;
        this.$client.muteVideo(false);
      }

      if (screenFlag == 1 && this.screenFlag) {
        if (this.$client.share && !this.screenFlag) {
          this.$message.error(this.$t("info.lang.shareError"));
          return;
        }
        this.screenFlag = false;
        this.$client.shareScreen(false);
      }

      if (roomFlag == 1 && this.flag) {
        this.$client.loginOut();
        document.getElementById("local_stream").innerHTML = "";
        this.flag = false;
        this.shareFlag = false;
        this.yuyinFlag = false;
        this.shipinFlag = false;
        this.screenFlag = false;
      }
    });
    this.Bus.$on("clientOut", (e) => {
      console.log("退出房间");
      document.getElementById("local_stream").innerHTML = "";
      this.list = [];
      this.$client.loginOut();
      this.flag = false;
      this.shareFlag = false;
      this.yuyinFlag = false;
      this.shipinFlag = false;
      this.screenFlag = false;

      this.$message.error(this.$t("info.lang.meetingError22") != "info.lang.meetingError22" ? this.$t("info.lang.meetingError22") : "您已退出房间");
    });
    this.Bus.$on("localFlag", (e) => {
      this.localFlag = e;
      this.flag = e;
      console.log(this.localFlag, this.flag);
    });
    this.Bus.$on("listChange", (e) => {
      if (e.length > 0) {
        // this.list = e;
        e.forEach((stream) => {
          this.list.find(function (value) {
            if (value.userId == stream.userId) {
              value.audioMuted = stream.audioMuted;
              value.hasAudio = stream.hasAudio;
              value.hasVideo = stream.hasVideo;
              value.videoMuted = stream.videoMuted;
            }
          });
        });
      }
    });

    this.Bus.$on("listChange2", (e) => {
      console.log("@@@@@@@@@listChange");
      let userId = e.getUserId();
      this.list.find(function (value) {
        if (value.userId == userId) {
          value.audioMuted = e.muted;
          value.hasAudio = !e.muted;
          value.hasVideo = e.tileId !== 0;
          value.videoMuted = e.tileId === 0;
          if (e.play) {
            e.play(userId);
          }
        }
      });
    });

    this.Bus.$on("addlist", (e) => {
      this.list.push({
        userId: e.getUserId(),
        audioMuted: true,
        videoMuted: true,
        hasAudio: false,
        hasVideo: false,
      });
      // var name = e.getUserId();
      // var node = document.createElement("div");
      // node.setAttribute("id", name);
      // node.setAttribute("class", "videoBox3");
      // var shadow = document.getElementById("shadow");
      // shadow.appendChild(node);

      // var subNode = document.createElement("video");
      // subNode.setAttribute("id", name + "_chime_video");
      // subNode.setAttribute("style", "width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 99");
      // node.appendChild(subNode);
      // if (e.play)
      //   e.play(name);
      this.flag = true;
      // setTimeout(() => {
      //   e.play(e.getUserId());
      // }, 1);
      // this.list = this.list.filter((stream) => {
      //   return stream.userId !== e;
      // });
    });
    this.Bus.$on("removelist", (e) => {
      var userId = e.getUserId();
      var box = document.getElementById(userId);
      if (box)
        box.remove();
      this.list = this.list.filter((stream) => {
        return stream.userId !== userId;
      });
      if (this.playValue === userId)
        this.playValue = -2;
      if (this.list.length === 0)
        this.flag = false;
    });

    //远方流列表

    this.Bus.$on("micError", () => {
      this.$message.error(this.$t("info.lang.meetingError9") != "info.lang.meetingError9" ? this.$t("info.lang.meetingError9") : "您的设备没有麦克风或未授权，无法推音频流");
    });
    //摄像机报错
    this.Bus.$on("cameraError", () => {
      this.$message.error(this.$t("info.lang.meetingError10") != "info.lang.meetingError10" ? this.$t("info.lang.meetingError10") : "您的设备没有摄像头或未授权，无法推视频流");
    });
    //屏幕分享报错

    this.Bus.$on("shareScreen", (e) => {
      this.shareFlag = true;
      this.sharename = this.$chat.searchName(e.id);
    });

    this.Bus.$on("shareClose", (e) => {
      this.shareFlag = false;
      this.sharename = "";
      this.screenFlag = false;
    });
    this.Bus.$on("loginS", (e) => {
      this.auth = this.$chat.role.auth;
    });

    this.Bus.$on("mute", (e) => {
      if ((e == 0 && this.$chat.role.auth != 5) || e == this.$chat.role.id) {
        this.$message.error(this.$t("info.lang.meetingError12") != "info.lang.meetingError12" ? this.$t("info.lang.meetingError12") : "主持人开启禁言");
        if (this.yuyinFlag) {
          this.yuyinFlag = !this.yuyinFlag;
          this.$client.muteAudio(this.yuyinFlag);
        }

        if (this.shipinFlag) {
          this.shipinFlag = !this.shipinFlag;
          this.$client.muteVideo(this.shipinFlag);
        }

        if (this.screenFlag) {
          this.screenFlag = false;
          this.$client.shareScreen(false);
        }
      }
    });

    this.Bus.$on("roomOut", (e) => {
      if (e == this.$chat.role.id) {
        this.$message.error(this.$t("info.lang.meetingError23") != "info.lang.meetingError23" ? this.$t("info.lang.meetingError23") : "您已经被踢出会场");
        setTimeout(() => {
          localStorage.removeItem("info");
          location.reload();
        }, 500);
      }
    });

    this.Bus.$on("bus_joinChimeRoom", () => {
      setTimeout(() => {
        this.$client.clientFlag = true;
        this.allowShare = true;
      }, 1000);

      // this.flag = true;

      this.$message({
        type: "success",
        message: this.$t("info.lang.vJoinInSuccess"),
      });
    });

    this.Bus.$on("bus_enterMeetingRoomA", () => {
      // console.log("CLLOG******************", "bus_enterMeetingRoomA");   

    });

    this.Bus.$on("bus_enterHall", () => {
      console.log("CLLOG******************", "bus_enterHall");   
      this.allowShare = false;      
      this.list = [];
      // this.$client.loginOut();
      this.flag = false;
      this.shareFlag = false;
      this.yuyinFlag = false;
      this.shipinFlag = false;
      this.screenFlag = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.ss {
  position: fixed;
  top: 0;
  right: 270px;
  bottom: 0;
  margin: auto;
  width: 65px;
  transition: 1s;
}

.cFunction {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  width: 150px;
  justify-content: space-around;
  height: 60px;
  // visibility: hidden;
  img {
    border-radius: 50%;

    cursor: pointer;
  }
}

.shadow {
  width: 250px;
  padding: 20px 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 90%;
  background: rgba($color: #11151e, $alpha: 0.5);
  margin-top: 60px;
  overflow-y: scroll;

  .videoBox {
    width: 250px;
    height: 150px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }

  .pos {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    transition: 0.5s;
    z-index: 9999;
  }
}

.qp {
  width: 80%;
  height: 80%;
  z-index: 10001;
}

.msg {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  background: rgba($color: #11151e, $alpha: 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;

  .pic {
    width: 250px;
    height: 120px;
    background: #bababa;
    position: absolute;
    bottom: 30px;
    font-weight: bolder;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eee;
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
  }

  .iconList {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 10px;
    width: 20px;
    cursor: pointer;
  }
}

.img {
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
}

.shadow::-webkit-scrollbar {
  width: 4px;
  opacity: 0.6;
}

.shadow::-webkit-scrollbar-track {
  background: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

#restream {
  position: absolute;
  width: 250px;
  padding: 20px 25px;
  position: absolute;
  overflow-y: scroll;
}
</style>
