<template>
    <div>
        <div class="resetPwdForm">
            <div class="showBox">
                <h3>{{ $t("info.lang.chgRoomPwd") }}</h3>
                <div class="boxInput">
                    <div class="radioInput">
                        <label><input name="setPwd" type="radio" value="1" checked @click="radioClick('1')"/>{{ $t("info.lang.setFreePassword") }}</label>
                        <label><input name="setPwd" type="radio" value="2" @click="radioClick('2')"/>{{ $t("info.lang.resetPassword") }}</label>
                    </div>
                    <transition enter-active-class="animated fadeIn">
                        <div class="input" v-if="setPwdFlag">
                            <el-input style="font-size: 12px;" v-model="inputPwd" placeholder="パスワードを入力してください。" @focus.stop="keyFlag(true)"
                                @blur="keyFlag(false)" ref="input" @click.stop=""></el-input>
                        </div>
                    </transition>
                </div>
                <div class="button">
                    <div class="bn1" @click="closeClick(false)">{{ $t("info.lang.setCancel") }}</div>
                    <div class="bn2" @click="confirmPwd()">{{ $t("info.lang.setOK") }}</div>
                </div>
                <!-- <img class="avatar1" src="../assets/role/qr.png" style="top: 205px" /> -->
            </div>
        </div>
    </div>
</template>

<script>
import { update_meetingPwd } from "../assets/api/index";
export default {
    data() {
        return {
            inputPwd: "",
            setPwdFlag: false
        }
    },
    methods: {
        closeClick(e) {
            this.Bus.$emit("onCloseResetPwd", e);
        },
        radioClick(e) {
            if (e == '2') {
                this.setPwdFlag = true;
            } else {
                this.setPwdFlag = false;
                this.inputPwd = '';
            }
        },
        keyFlag(e) {
            this.Bus.$emit("keyFlag", e);
        },
        confirmPwd() {
            var radio = document.querySelector('input[name="setPwd"]:checked');
            // if (this.inputPwd == this.meetingPwd) {
            //     this.pwdErrFlg = false;
            //     this.Bus.$emit("onConfirmResetPwd", this.pwdErrFlg);
            // } else {
            //     this.$message.error(this.$t("info.lang.pwdCheckError"));
            //     this.Bus.$emit("onConfirmResetPwd", this.pwdErrFlg);
            // }

            if (radio.value == '1') {
                this.inputPwd = 'No_PASSWORD_';
            } else if (radio.value == '2') {
                if (this.inputPwd == '' || this.inputPwd == null) {
                    this.$message.error(this.$t("info.lang.rePwdErr"));
                    return;
                }
            }
            this.changePwd();
            this.Bus.$emit("onCloseResetPwd", false);
        },

        changePwd() {
            update_meetingPwd({
                roomId: '0001',
                meetingPwd: this.inputPwd
            }).then((res) => {
                console.log(res);
                if (res.code == 0) {
                    this.$message({
                        message: this.$t("info.lang.chgPwdSuccess"),
                        type: "success",
                    });
                    // } else {
                    //   this.$message.error(this.$t("info.lang.pwdCheckError"));
                    //   this.Bus.$emit("onConfirmPwd", this.pwdErrFlg);
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.showBox {
    background-color: rgb(38, 38, 38);
    padding: 0 25px;
    background: #262626;
    border-radius: 6px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 300px;

    h3 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 26px;
        border-bottom: 1px solid #191919;
        color: white;
        padding: 10px 0;
    }

    .avatar1 {
        width: 80px;
        height: 80px;

        background: #fff;

        border-radius: 50%;
        cursor: pointer;
        margin-right: 15px;
    }

    p {
        width: 70px;
        color: white;
        margin-left: 5px;
        font-size: 14px;
    }
}

.boxInput {
    margin-top: 17px;
    padding: 0px 10px;
}

.radioInput{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.radioInput>label {
    margin-bottom: 15px;
}

.radioInput>label>input {
    margin-right: 15px;
}

.input {
    // width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    font-size: 12px;
    height: 40px;
}

// .input .el-input .el-input__inner {
//     height: 35px;
// }

.button {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 25px;
}

.bn1 {
    width: 80px;
    height: 27px;
    background: #262626;
    border: 1px solid #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 25px;
    font-size: 12px;
}

.bn2 {
    width: 80px;
    height: 27px;
    background: #ff0000;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
</style>