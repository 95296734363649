<template>
  <div>
    <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
      <div class="caozuo" v-if="tishi">
        <div class="caozuoButton" @click="tishi = false">OK</div>
      </div>
    </transition>
    <!-- <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <Tishi1 v-if="!tishi" />
    </transition> -->
    <img class="avatar" :src="url ? url : require('../assets/img/role1.jpg')" @click="msgShow = !msgShow" />
    <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
      <div class="showBox" v-if="msgShow">
        <h3>{{ $t("info.lang.setting") }}</h3>
        <div class="input">
          <img class="avatar1" :src="url ? url : require('../assets/img/role1.jpg')" />

          <el-input v-model="nickname" placeholder="名前を入力してください。" @focus.stop="keyFlag(true)" @blur="keyFlag(false)"
            ref="input" @click.stop=""></el-input>
        </div>
        <div class="button">
          <div class="bn1" @click="msgShow = false">{{ $t("info.lang.setCancel") }}</div>
          <div class="bn2" @click="confirm">{{ $t("info.lang.setOK") }}</div>
        </div>

        <!-- <img class="avatar1" src="../assets/role/qr.png" style="top: 205px" /> -->
      </div>
    </transition>

    <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100%">
      <transition enter-active-class="animated fadeInUp animate__faster"
        leave-active-class="animated fadeOutUp animate__faster">
        <ResetPwdForm v-if="resetPwdShow" @close="closeFlag" />
      </transition>
    </div>
    <div style="position: absolute; left: 0; top: 0; width: 100%">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#262626" text-color="#fff" active-text-color="#fff">
        <div src="" class="img"></div>

        <el-menu-item index="5" v-if="!type"><img src="../assets/newImg/pf.png" class="icon1" />{{
          $t("info.lang.decoration")
        }}</el-menu-item>

        <!-- <el-submenu index="6">
          <template slot="title"
            ><img src="../assets/newImg/wz.png" class="icon1" />传送</template
          >

          <el-menu-item index="6-1">展区</el-menu-item>
          <el-menu-item index="6-2">观众席</el-menu-item>
          <el-menu-item index="6-3">vip席</el-menu-item>
        </el-submenu> -->

        <el-submenu index="1" v-if="auth == 5">
          <template slot="title"><img src="../assets/newImg/wz.png" class="icon1" />{{
            $t("info.lang.manager")
          }}</template>

          <!-- <el-submenu index="1-2">
          <template slot="title">播放动画</template>
          <el-menu-item index="1-2-1">动画一</el-menu-item>
          <el-menu-item index="1-2-2">动画二</el-menu-item>
        </el-submenu> -->

          <!-- <el-menu-item index="1-3">{{ $t("info.lang.reAnima") }}</el-menu-item> -->
          <!-- <el-menu-item index="1-4">同步相机</el-menu-item> -->
          <el-menu-item index="1-8">{{ $t("info.lang.chgRoomPwd") }}</el-menu-item>
          <el-menu-item index="1-5" v-if= "inMeetingRoomA">{{ $t("info.lang.chgManager") }}</el-menu-item>
          <el-menu-item index="1-6" v-if= "inMeetingRoomA">{{ $t("info.lang.muteAll") }}</el-menu-item>

          <el-menu-item index="1-7" v-if= "inMeetingRoomA">{{ $t("info.lang.chatClear") }}</el-menu-item>
        </el-submenu>
        <!-- <el-menu-item index="7">返回</el-menu-item> -->
        <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
          <chose-role v-if="changeFlag" @close="closeFlag" />
        </transition>
        <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
          <chose-role v-if="changeFlag" @close="closeFlag" />
        </transition>
        <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
          <UserList :flag="userFlag" @close="closeFlag" v-if="userFlag" />
        </transition>
        <p class="loginOut" @click="loginOut">
          <img src="../assets/newImg/tc.png" class="icon1" />{{ $t("info.lang.logOut") }}
        </p>
        <p class="pageLink">
          <img src="../assets/newImg/function/presoft_logo.png" class="icon1" />
          <a href="https://presoft.jp/" target="_blank">百易ソフト東京HPへ</a>
        </p>

        <p class="lt_site" @click="mailClick" id="feedback">
          <img src="../assets/newImg/function/email_white_s.png" alt="お問い合わせ">お問い合わせ
        </p>
      </el-menu>

      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <MailInfo v-if="mailShow" @close="closeFlag" />
      </transition>

      <div class="bottomfunction">
        <div v-for="(item, index) in btList" :key="index" @click="btfun(index)">
          <div v-if="index < 3 && item.isShow">
            <img :src="item.img" />
            <p style="color: white">{{ item.text }}</p>
          </div>
          <div v-if="sitFlag && index > 2 && index < 5 && item.isShow">
            <img :src="item.img" />
            <p style="color: white" v-if="index == 4">
              {{ screenFlag ? item.text1 : item.text }}
            </p>
            <p style="color: white" v-else>{{ item.text }}</p>
          </div>
          <!-- <div v-if="index > 4 && item.isShow">
            <img :src="item.img" />
            <p style="color: white">{{ item.text }}</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import choseRole from "./choseRole.vue";
import choseMsg from "./choseMsg.vue";
import UserList from "./userList.vue";
import Tishi1 from "./tishi1.vue";
import MailInfo from "./mailInfo.vue";
import ResetPwdForm from "./resetPwdForm.vue";
import { save, rename } from "../assets/api/index";
import Bus from "../../common/js/bus";
export default {
  components: { choseRole, choseMsg, UserList, MailInfo, ResetPwdForm, Tishi1 },
  data() {
    return {
      activeIndex: "99",
      changeFlag: false,
      msgFlag: false,
      roleList: [
        require("../assets/role/role2.png"),
        require("../assets/role/role1.png"),
        require("../assets/role/role3.png"),
        require("../assets/role/role5.png"),
        require("../assets/role/role6.png"),
        require("../assets/role/role4.png"),
      ],

      role: "",
      auth: 0,
      userFlag: false,
      yuyinFlag: false,
      shipinFlag: false,
      screenFlag: false,
      sitFlag: false,
      mailShow: false,
      resetPwdShow: false,
      type: "",
      url: "",
      input: "",
      btList: [
        { img: require("../assets/newImg/截屏.png"), text: this.$t('info.lang.action3'), isShow: true },
        { img: require("../assets/newImg/点赞.png"), text: this.$t('info.lang.action1'), isShow: true },
        { img: require("../assets/newImg/鼓掌.png"), text: this.$t('info.lang.action2'), isShow: true },
        { img: require("../assets/newImg/起立.png"), text: this.$t('info.lang.action4'), isShow: true },
        { img: require("../assets/newImg/聚焦.png"), text: this.$t('info.lang.action5'), text1: this.$t('info.lang.action6'), isShow: true },
        // { img: require("../assets/newImg/截屏.png"), text: '看视频', isShow: true },
      ],

      tishi: true,
      url: "",
      msgShow: false,
      nickname: "",
      inMeetingRoomA: false,
    };
  },
  methods: {
    confirm() {
      rename({
        id: this.$chat.role.id,
        name: this.nickname,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t("info.lang.alertMsg07") != "info.lang.alertMsg07" ? this.$t("info.lang.alertMsg07") : "修改昵称成功");
          let msg = 1056 + ",";
          let info = { msg: "setName", data: this.nickname };
          msg += JSON.stringify(info);
          this.$chat.ws.send(msg);
          this.Bus.$emit("changeName", this.nickname);
        }
        this.msgShow = false;
      });
    },
    closeFlag(e) {
      if (e) {
        this.msgFlag = e;
      } else {
        this.changeFlag = e;
        this.msgFlag = e;
        this.userFlag = e;
        this.mailShow = e;
        this.resetPwdShow = e;
      }
    },

    changeAvatar(e) {
      console.log(e);
      this.role.avatar = e;
      this.$chat.role.avatar = e;
      save({
        name: this.$chat.role.name,
        info: JSON.stringify(this.$chat.role),
      }).then((res) => { });
      // localStorage.setItem("info", JSON.stringify(this.$chat.role));
    },
    handleSelect(key, keyPath) {
      if (keyPath[1] == "2-1") {
        let clip;
        if (key == "2-1-1") {
          clip = "Greeting";
        } else if (document.o2.role.sit) {
          clip = "clap";
        }
        if (clip) {
          document.o2.role.playClip(clip);
          let msg = 1056 + ",";
          let info = { msg: "playClip", data: clip };
          msg += JSON.stringify(info);
          this.$chat.ws.send(msg);
        }
        console.log("动作", key);
        if (key == "3-1-2") {
          let str = "1056,gif,0";
          this.$chat.ws.send(str);
          //document.o2.gif_pop(document.o2.player,0);
        }
        if (key == "3-1-4") {
          let str = "1056,gif,1";
          this.$chat.ws.send(str);
        }
      }

      if (keyPath[1] == "3-2") {
        this.jushou();
      }

      if (keyPath[1] == "4-1") {
        this.zipai();
      }

      if (keyPath[1] == "3-3") {
        this.qili();
      }

      if (keyPath[1] == "1-2") {
        if (key == "1-2-1") {
          document.o2.play_animation(0);
        }
        if (key == "1-2-2") {
          document.o2.play_animation(1);
        }
        console.log("动画", key);
      }

      if (keyPath[1] == "1-3") {
        this.$prompt(this.$t("info.lang.reAnimaInfo"), this.$t("info.lang.reAnimaInfoHit"), {
          confirmButtonText: this.$t("info.lang.confirm"),
          cancelButtonText: this.$t("info.lang.cancel"),
        }).then(({ value }) => {
          document.o2.screen_url(value);
        });
      }

      if (keyPath[1] == "1-6") {
        let data = { msg: "allmute", data: 0 };
        this.$chat.ws.send("1056," + JSON.stringify(data));
      }

      if (keyPath[1] == "1-7") {
        let data = { msg: "cleanMsg", data: 0 };
        this.$chat.ws.send("1056," + JSON.stringify(data));
      }

      if (keyPath[1] == "1-8") {
        // this.$prompt(this.$t("info.lang.rePwdInfo"), {
        //   confirmButtonText: this.$t("info.lang.confirm"),
        //   cancelButtonText: this.$t("info.lang.cancel"),
        // }).then(({ value }) => {
        //   // if (value != '' && value != null) {
        //   this.changePwd(value);
        //   // }
        // }).catch(() => {});

        if (!this.resetPwdShow) {
          this.resetPwdShow = true;
        }
      }

      if (keyPath[2] == "2-2-1") {
        this.open(1);
      }

      if (keyPath[2] == "2-2-2") {
        this.open(2);
      }

      if (keyPath[2] == "2-2-3") {
        this.open(3);
      }

      if (keyPath[1] == "1-4") {
        console.log("相机同步");
      }

      if (keyPath[1] == "4-3") {
        this.loginOut();
      }
      if (keyPath[1] == "1-5") {
        this.userFlag = !this.userFlag;
      }

      if (keyPath[2] == "3-4-1") {
        console.log("第三人称视角");
        document.o2.switch_view(0);
      }
      if (keyPath[2] == "3-4-2") {
        console.log("展台视角");
        document.o2.switch_view(1);
      }
      if (keyPath[2] == "3-4-3") {
        console.log("大屏幕视角");
        document.o2.switch_view(2);
      }

      if (keyPath[0] == "5") {
        if (document.o2.role.state == "sit") {
          this.$message.error(this.$t("info.lang.meetingError11") != "info.lang.meetingError11" ? this.$t("info.lang.meetingError11") : "坐下无法换装");
          return;
        }
        this.changeFlag = !this.changeFlag;
        this.msgFlag = false;
      }

      if (keyPath[1] == "6-2") {
        document.o2.setRolePos(19418, 1622, 797);
      } else if (keyPath[1] == "6-1") {
        document.o2.setRolePos(4122, 2521, -26166);
      } else if (keyPath[1] == "6-3") {
        document.o2.setRolePos(9780, 124, 13205);
      }

      if (keyPath[0] == "7") {
        localStorage.setItem("dreamType", 0);
        location.reload();
        done();
      }
    },

    zipai() {
      var date = new Date().getTime();
      // var name = `自拍${date}`;
      this.compress(document.o2.shot(), 1920, 1080).then((res) => {
        this.$notify({
          title: "成功",
          message: this.$t("info.lang.shotSuccess"),
          type: "success",
        });
      });
    },

    compress(base64String, w, quality) {
      let a = document.createElement("a");

      a.style.display = "none";
      var that = this;
      var getMimeType = function (urlData) {
        var arr = urlData.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        // return mime.replace("image/", "");
        return mime;
      };
      var newImage = new Image();
      var imgWidth, imgHeight;

      var promise = new Promise((resolve) => (newImage.onload = resolve));
      newImage.src = base64String;
      var img = document.o2.reportInfo.logo4
        ? document.o2.reportInfo.logo4
        : require("../assets/newImg/banner1.png");
      return promise.then(() => {
        imgWidth = newImage.width;
        imgHeight = newImage.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = (w * imgHeight) / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = (w * imgWidth) / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);

        let imgobj = new Image();
        console.log(img);
        imgobj.src = img;
        imgobj.crossOrigin = "Anonymous";
        imgobj.onload = function () {
          ctx.drawImage(
            imgobj,
            canvas.width - 370 / 2 - 20,
            canvas.height - 160 / 2 - 20,
            370 / 2,
            160 / 2
          );

          //   let imgobj1 = new Image();
          //   imgobj1.src = "https://o2vr.net/upload/img/o2reportBanner1.png";

          //   imgobj1.onload = function () {
          //     ctx.drawImage(imgobj1, 20, canvas.height - 290 / 5 - 20, 1203 / 5, 290 / 5);
          var base64 = canvas.toDataURL(getMimeType(base64String), quality);
          var blob = that.dataURLToBlob(base64);
          a.setAttribute("href", URL.createObjectURL(blob));
          a.setAttribute("download", "画面ショット.png");
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(blob);
          document.body.removeChild(a);
          //     };
        };
      });
    },

    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    getStatus(e) {
      if (e.time0 == null) {
        return 0; //空闲
      }
      var time = new Date().getTime();

      if (time > Number(e.time0) && time < Number(e.time1)) {
        return 1; //正在使用
      }

      if (time < e.time1) {
        return 2; //已预约
      }

      if (time > e.time1) {
        return 3;
        //已过期;
      }
    },

    qili() {
      this.Bus.$emit("stand", "");
    },
    jushou() {
      let role = document.o2.role;
      if (role && role.sit) {
        let cmd = role.bHand ? "handDown" : "handUp";
        role.bHand = !role.bHand;
        let data = { msg: cmd, data: role.sit };
        this.$chat.ws.send("1056," + JSON.stringify(data));
      }
    },
    pose(key) {
      let clipName = "";
      if (key == "3-1") {
        if (document.o2.role.state != "idle") {
          this.$message.error(this.$t("info.lang.meetingError28") != "info.lang.meetingError28" ? this.$t("info.lang.meetingError28") : "该动作只有站立才能使用");
          return;
        }
        console.log("动作1");
        clipName = "站立招手";
      }
      if (key == "3-2") {
        if (document.o2.role.state != "idle") {
          this.$message.error(this.$t("info.lang.meetingError28") != "info.lang.meetingError28" ? this.$t("info.lang.meetingError28") : "该动作只有站立才能使用");
          return;
        }
        clipName = "站立挥手";
      }
      if (key == "3-3") {
        if (document.o2.role.state != "sit") {
          this.$message.error(this.$t("info.lang.meetingError27") != "info.lang.meetingError27" ? this.$t("info.lang.meetingError27") : "该动作只有坐下才能使用");
          return;
        }
        clipName = "坐着鼓掌";
      }

      if (clipName && clipName != "") {
        document.o2.role.playClip(clipName, false, false);
        let msg = "playAnimationClip,";
        msg += document.o2.role.name;
        msg += "," + clipName;
        this.$chat.ws.send(msg);
      }
    },

    loginOut() {
      this.$confirm(this.$t("info.lang.logOutInfo"), {
        confirmButtonText: this.$t("info.lang.setOK"),
        cancelButtonText: this.$t("info.lang.setCancel")
      })
        .then((_) => {
          localStorage.removeItem("info");
          location.reload();
          done();
        })
        .catch((_) => { });
    },

    btfun(e) {
      if (e == 0) {
        this.zipai();
      } else if (e == 1) {
        let str = "1056,gif,1";
        this.$chat.ws.send(str);
      } else if (e == 2) {
        let str = "1056,gif,0";
        this.$chat.ws.send(str);
      } else if (e == 3) {
        this.qili();
      } else if (e == 4) {
        this.screenFlag = !this.screenFlag;
        if (this.screenFlag) {
          // ズームイン時は「いいね」「拍手」「起立」ボタンを表示しない
          this.btList[1].isShow = false;
          this.btList[2].isShow = false;
          this.btList[3].isShow = false;
          document.o2.switch_view(2);
          this.text = "返回";
        } else {
          
          this.btList[1].isShow = true;
          this.btList[2].isShow = true;
          this.btList[3].isShow = true;
          document.o2.switch_view(0);
          this.text = "聚焦";
        }
      } else if (e == 5) {
        // this.screenFlag = !this.screenFlag;
        // if (this.screenFlag) {
        //   document.o2.switch_view(4);
        // } else {
        //   document.o2.switch_view(0);
        // }

        // console.log("%%%%%%%%%%%%%%%%",document.o2);
      }
    },
    mailClick() {
      if (!this.mailShow) {
        this.mailShow = true;
      } else {
        this.mailShow = false;
      }
    },
    keyFlag(e) {
      this.Bus.$emit("keyFlag", e);
    }
  },
  created() {
    this.Bus.$on("loginS", (e) => {
      this.role = this.$chat.role;
      if (!this.role.avatar) {
        this.role.avatar = 0;
      }
      this.auth = this.$chat.role.auth;
      this.type = this.$chat.role.type;
      this.url = this.$chat.role.url;

      this.nickname = this.$chat.role.name;
    });

    this.Bus.$on("renzheng", () => {
      this.msgFlag = true;
    });

    this.Bus.$on("loginS", () => {
      this.auth = this.$chat.role.auth;
    });

    this.Bus.$on("onRoleLoaded", (role) => {
      role.onStateChg.push((state) => {
        if (state == "sit") {
          this.sitFlag = true;
        } else {
          this.sitFlag = false;
        }
      });
    });

    this.Bus.$on("onCloseMail", (e) => {
      this.mailShow = e;
    });

    this.Bus.$on("onCloseResetPwd", (e) => {
      this.resetPwdShow = e;
    });
    
    this.Bus.$on("bus_enterMeetingRoomA", (e) => {
      //CLLOG 进入后显示Host 相关三个子菜单
      this.inMeetingRoomA = true;
    });
    this.Bus.$on("bus_enterHall", (e) => {
      //CLLOG 离开后隐藏Host 相关三个子菜单
      this.inMeetingRoomA = false;
    });
  }
};
</script>
<style lang="scss" scoped>
.img {
  width: 40px;
  float: left;
  margin-right: 20px;
  // margin-top: 10px;
  margin-left: 20px;
  border-radius: 50%;
  cursor: pointer;
  height: 60px;
}

.avatar {
  width: 40px;
  height: 40px;
  position: fixed;
  left: 20px;
  top: 10px;
  background: #fff;
  z-index: 50;
  border-radius: 50%;
  cursor: pointer;
}

.loginOut {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  float: right;
  margin-top: 18px;
  display: flex;
}

.pageLink {
  padding: 0 20px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  float: right;
  margin-top: 18px;
  display: flex;

  a {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
  }
}

.lt_site {
  margin-top: 8px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  text-align: center;
  color: #fff;
  font-size: 16px;
  float: right;
  align-items: center;
  cursor: pointer;
}

.lt_site img {
  width: 45px;
  margin-right: 5px;
}

.bottomfunction {
  position: fixed;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 500px;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transform: scale(0.8);
}

.icon1 {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.caozuo {
  width: 418px;
  height: 361px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: url("../assets/newImg/caozuo2_ja.png");

  .caozuoButton {
    width: 107px;
    height: 45px;
    background: #1f2c3a;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }
}

.showBox {
  background-color: rgb(38, 38, 38);
  width: 258px;
  height: 240px;
  padding: 0 15px;
  background: #262626;
  border-radius: 6px;
  position: fixed;
  left: 20px;
  top: 75px;
  border-radius: 5px;

  h3 {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid #191919;
    color: white;
    padding: 10px 0;
  }

  .avatar1 {
    width: 80px;
    height: 80px;

    background: #fff;

    border-radius: 50%;
    cursor: pointer;
    margin-right: 15px;
  }

  p {
    width: 70px;
    color: white;
    margin-left: 5px;
    font-size: 14px;
  }
}

.input {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 17px;
}

.button {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.bn1 {
  width: 80px;
  height: 27px;
  background: #262626;
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 25px;
  font-size: 12px;
}

.bn2 {
  width: 80px;
  height: 27px;
  background: #ff0000;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
</style>
