<template>
  <div v-if="flag">
    <div class="p" @click="userFlag = !userFlag">申请列表</div>
    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <div class="userList" v-if="userFlag">
        <div v-if="list.length > 0">
          <div class="msg" v-for="(item, index) in list" :key="index">
            <div>
              <p class="p1">{{ item.name }}</p>
              <p class="p2">申请语音权限</p>
            </div>

            <div style="display: flex">
              <p class="p3" @click="func(item, index, true)">允许</p>
              <p class="p4" @click="func(item, index, false)">拒绝</p>
            </div>
          </div>
        </div>
        <div v-else style="color: #fff">没有更多申请了~</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      userFlag: false,
      flag: false,
    };
  },
  methods: {
    func(a, b, c) {
      var obj = { msg: "zhuchiren", id: a.id, flag: c, zw: a.zw };
      this.$chat.ws.send(
        `${this.$chat.connectInfo.CMD_USER_CUSTOM},${JSON.stringify(obj)}`
      );
      this.list.splice(b, 1);
    },
  },
  mounted() {
    this.Bus.$on("shenqing", (e) => {
      this.userFlag = true;
      this.list.push(e);
      console.log(e);
    });
    this.Bus.$on("bus_joinChimeRoom", () => {
      if (
        this.$chat.role.auth == 5 &&
        this.$client.type == 0 &&
        this.$chat.user_group == this.$client.roomId
      ) {
        this.flag = true;
      }
    });
    this.Bus.$on("zhuchirenhuifu", (e) => {
      if (e) {
        this.$client.typeFlag = true;
        this.$message({
          type: "success",
          message: this.$t("info.lang.alertMsg28")!="info.lang.alertMsg28"?this.$t("info.lang.alertMsg28"):"您的权限申请成功",
        });
      } else {
        this.$message.error(this.$t("info.lang.meetingError21")!="info.lang.meetingError21"?this.$t("info.lang.meetingError21"):"您的请求已被拒绝");
      }
      // this.$chat.ws.send(`handDown,${this.$chat.role.name},${this.$client.roomId}`);
      // this.$player.handPanel.visible = false;
    });

    this.Bus.$on("shenqing1", (e) => {
      var i = this.list.findIndex((element) => {
        return element == e;
      });
      this.list.splice(i, 1);
    });
  },
};
</script>

<style lang="scss" scoped>
.p {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  float: right;

  position: fixed;
  right: 290px;

  top: 20px;
}
.userList {
  width: 331px;
  height: 426px;
  background: #262626;
  border-radius: 4px;
  padding-top: 36px;
  z-index: 99999;
  position: fixed;
  right: 24px;
  top: 74px;
  padding-top: 30px;
  overflow-y: scroll;
  .msg {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    border-bottom: 1px solid #393939;
    height: 60px;
    align-items: center;
    .p1 {
      font-size: 14px;

      font-weight: 400;
      text-align: left;
      color: #888888;
    }
    .p2 {
      font-size: 14px;

      font-weight: 400;
      text-align: left;
      color: #dfdfdf;
    }
    .p3 {
      font-size: 14px;

      font-weight: 400;
      text-align: left;
      color: #97f3a0;
      margin-right: 20px;
      cursor: pointer;
    }
    .p4 {
      font-size: 14px;

      font-weight: 400;
      text-align: left;
      color: #878787;
      cursor: pointer;
    }
  }
}

.userList::-webkit-scrollbar {
  width: 4px;
  opacity: 0.6;
}

.userList::-webkit-scrollbar-track {
  background: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.userList::-webkit-scrollbar-thumb {
  background-color: #1dd3b0;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
</style>
