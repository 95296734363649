<template>
  <div :class="chatFlag ? 'chatRoom' : 'chatRoom closeRoom'" @mouseover="enter" id="chatroom" @click.stop="" v-if="allowChat">
    <h1>
      <div class="roomList" :class="chatFlag ? 'roomList' : ' roomList roomListClose'">
        <p v-for="(item, index) in cheatList" :key="index" :class="index == value ? 'active' : ''"
          @click="chooseRoom(index)">
          {{ item.name }}
        </p>
      </div>
      <div class="function">
        <img class="icon" src="../assets/newImg/chatRoom/close.png" @click="chatFlag = !chatFlag" />
        <img class="icon" src="../assets/newImg/chatRoom/group.png" v-if="chatFlag" @click="groupFlag = !groupFlag" />
      </div>
    </h1>
    <div class="main">
      <div class="chat" id="chat">
        <div v-for="(item, index) in msgList[value]" :key="index">
          <div class="msgList" v-if="!item.url">
            <div class="name" :style="item.name == $chat.role.name ? 'color: #FF0000;' : 'color:orange'">
              {{ item.name }}:
            </div>
            <div class="sendMsg">{{ item.msg }}</div>
          </div>

          <div class="msgList" v-if="item.url">
            <div class="name" :style="item.name == $chat.role.name ? 'color: #FF0000;' : 'color:orange'">
              {{ item.name }}:
            </div>
            <a :href="item.url" target="_blank" class="sendMsg">{{ item.msg }}</a>
          </div>
        </div>
      </div>

      <div class="group" v-if="groupFlag">
        <h3>
          {{ num + playerList.length }}
          {{ $t("info.lang.peopleOnline") }}
        </h3>
        <div v-for="(item, index) in playerList" :key="index" class="msg">
          <div class="circle"></div>
          <p>{{ item.name }}</p>
          <div style="flex: 1"></div>
          <img src="../assets/newImg/chatRoom/chat.png" class="logo" v-if="item.id != $chat.role.id && item.id < 10000"
            @click="onlyChat(item.name, item.name, item.id)" />
        </div>
      </div>
    </div>
    <div class="send">
      <div style="width: 80%">
        <el-input :placeholder= "colorVal" :class="[inputfocusFlag?'inputFocused':'inputLoseFocused']"  v-model="input" clearable @focus.stop="keyFlag(true)"
          @blur="keyFlag(false)" ref="input" @click.stop="" >
        </el-input>
      </div>
      <el-button type="success" @click="send">{{ $t("info.lang.send") }}</el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Color } from "three";
// import { get_avaters } from "../assets/api/index";
export default {
  data() {
    return {
      cheatList: [{ name: this.$t("info.lang.world"), id: 0 }],
      playerList: [],
      value: 0,
      input: "",
      groupFlag: true,
      chatFlag: true,
      inputfocusFlag: false,
      msgList: [[]],
      name: "",
      auth: 0,
      num: 0,
      colorVal : this.$t('info.lang.sendInfo'),
      allowChat: false,
    };
  },
  methods: {
    chooseRoom(e) {
      this.value = e;
    },
    keyFlag(e) {
      console.log("123");
      this.Bus.$emit("keyFlag", e);
      this.inputfocusFlag = e;
      if(e){
        this.colorVal = '';
      }
      else{
        this.colorVal = this.$t('info.lang.sendInfo');
      }
      
    },
    enter() {
      console.log("enter");
    },
    send() {
      if (!this.input) {
        this.$message.error(this.$t("info.lang.sendError"));
        return;
      }

      if (this.$chat.role.id > 10000) {
        this.$message.error(this.$t("info.lang.meetingError19") != "info.lang.meetingError19" ? this.$t("info.lang.meetingError19") : "游客暂无权限聊天");
        return;
      }
      if (this.value == 0) {
        this.$chat.send(`${this.$chat.connectInfo.CMD_USER_CHATS},${this.input}`);
        // console.log(this.$chat.role.auth)

        // if(this.$chat.role.auth<4){
        //   this.$message.error('只有管理员才能公屏聊天')
        //   return
        // }
        // let url = `https://api.wh12345.net:9300/chats/?name=${this.$chat.role.name}&msg=${this.input}`;
        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();
        console.log(this.$chat.role.auth);
        //document.o2.chat_pop(document.o2.player, this.input);

        // let url = `https://api.wh12345.net:9300/chats/?name=${this.$chat.role.name}&msg=${this.input}`;
        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();
      } else {
        this.$chat.send(
          `${this.$chat.connectInfo.CMD_USER_CHAT},${this.cheatList[this.value].id},${this.input
          },${this.value}`
        );
        // var e = `chat,only,${this.$chat.role.name},${this.input},${
        //   this.$chat.role.name
        // },${this.cheatList[this.value].name}, ${this.value}`;

        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();

        // let url = `https://api.wh12345.net:9300/chat/?name0=${
        //   this.$chat.role.name
        // }&name1=${this.cheatList[this.value].name}&value=${this.value}&msg=${this.input}`;
        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();
      }

      // this.$chat.send(e);
      this.input = "";
    },

    getFile() {
      var upload = document.getElementById("uploadjl");

      var file = upload.files[0];
      var that = this;
      if (that.value == 0) {
        that.$message.error("不能在公屏发送文件");
      } else {
        that.$o2Function.getMD5(file, function (md5) {
          let formData = new FormData();
          formData.append("md5", md5);
          axios
            .post("https://api.wh12345.net/istation/media_exist.php?", formData, {
              "Content-Type": "multipart/form-data",
            })
            .then((res) => {
              console.log(res);
              let formData = new FormData();
              formData.append("file", file);
              axios
                .post("https://api.wh12345.net/istation/media_upload.php", formData, {
                  "Content-Type": "multipart/form-data",
                })
                .then((res) => {
                  let fileurl = document.showroom.encode64(
                    document.showroom.utf16to8(res.data.url)
                  );

                  let filename = document.showroom.encode64(
                    document.showroom.utf16to8(res.data.name)
                  );
                  let url = `https://api.wh12345.net:9300/file/?name0=${that.$chat.role.name
                    }&name1=${that.cheatList[that.value].name}&value=${that.value
                    }&filename=${filename}&fileurl=${fileurl}`;

                  // tfile({
                  //   name0:that.$chat.role.name,
                  //   name1:that.cheatList[that.value].name,
                  //   value:that.value,
                  //   filename:res.data.name,
                  //   fileutl:res.data.url
                  // })
                  let xhr = new XMLHttpRequest();
                  xhr.open("get", url, true);
                  xhr.responseType = "text";
                  xhr.send();
                });
            });
        });
      }
    },
    onlyChat(a, b, c) {
      console.log(a, b);
      if (this.$chat.role.name == b) {
        console.log("不能与自己聊天");
        return;
      }
      var found = this.cheatList.filter((el) => el.name === b).length > 0; // .length returns 1 if id exist in an array

      if (!found) {
        this.cheatList.push({
          name: a,
          id: c,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === b);
      this.value = value;
    },

    to_footer() {
      var div = document.getElementById("chat");
      setTimeout(() => {
        div.scrollTop = div.scrollHeight;
      }, 100);
    },
    blur() {
      console.log(this.$refs.input);
      if (this.$refs.input) {
        this.$refs.input.blur();
      }
    },
  },
  created() {
    var that = this;

    document.body.addEventListener("click", this.blur);

    // get_avaters().then((res) => {
    //   console.log(res);
    //   this.num = parseInt(res.nums);
    // });

    document.onkeydown = function (el) {
      if (el.keyCode == 13 && that.inputfocusFlag) {
        if (that.chatFlag) {
          that.send();
          that.$refs.input.blur();
        }
      } else {
      }
    };

    this.Bus.$on("changeName", (e) => {
      console.log(e);
      console.log(this.playerList);
      var found = this.playerList.findIndex((el) => el.id == e.id);
      if (found) {
        this.playerList[found].name = e.data;
      }
    });
    this.Bus.$on("cleanMsg", (e) => {
      this.$message.error(this.$t("info.lang.chatClearError"));
      this.$chat.msgList = [[]];
      this.msgList = [[]];
    });

    this.Bus.$on("msgList", (e) => {
      this.msgList = e;
      this.to_footer();
    });

    this.Bus.$on("playerList", (e) => {
      this.playerList = e;
    });

    this.Bus.$on("loginS", (e) => {
      this.auth = this.$chat.role.auth;
    });

    this.Bus.$on("reciveMsg", (e) => {
      this.name = this.$chat.role.name;
      var found = this.cheatList.filter((el) => el.name === e.from).length > 0;
      if (!found) {
        this.msgList.push([]);
        this.cheatList.push({
          name: e.name,
          id: e.id,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === e.from);
      this.value = value;
      this.msgList[value].push({
        name: e.name,
        msg: e.msg,
        url: e.url,
      });
      this.$chat.msgList = this.msgList;
      this.to_footer();
    });
    
    this.Bus.$on("bus_enterMeetingRoomA", () => {
      // console.log("CLLOG******************", "bus_enterMeetingRoomA");  
      this.allowChat = true;
    });

    this.Bus.$on("bus_enterHall", () => {
      this.allowChat = false;      
    });
  },
};
</script>

<style scoped lang="scss">
.chatRoom {
  width: 457px;
  height: 267px;
  background: url("../assets/newImg/chat.png");
  position: absolute;

  padding: 0 30px;
  left: 20px;
  bottom: 35px;
  z-index: 999;

  transition: 0.5s;

  h1 {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .roomList {
      width: 350px;
      display: flex;
      align-items: center;
    }

    .roomListClose {
      width: 160px;
      overflow: hidden;
    }

    p {
      // width: 50px;
      height: 24px;
      background: #6a6a6a;
      border-radius: 2px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      text-align: center;
      color: #afafaf;
      margin-left: 8px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      padding: 0 5px;
    }

    .active {
      color: #fff;
      background: #ff0000;
      transition: 0.5s;
    }

    .icon {
      width: 24px;
      height: 24px;
      float: right;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .main {
    height: 185px;
    display: flex;
    margin-bottom: 10px;

    .chat {
      overflow-y: scroll;
      width: 100%;
      flex: 1;

      .msgList {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        margin-bottom: 8px;

        .name {
          margin: 0 12px;

          width: 70px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }

        .sendMsg {
          color: #fff;
          flex: 1;
          text-align: left;
          margin-right: 12px;
          word-wrap: break-word;
          width: 180px;
          // display: flex;
          // align-items: stretch;
        }
      }
    }

    .chat::-webkit-scrollbar,
    .group::-webkit-scrollbar {
      width: 4px;
      opacity: 0.6;
    }

    .chat::-webkit-scrollbar-track,
    .group::-webkit-scrollbar-track {
      background: gray;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    .chat::-webkit-scrollbar-thumb,
    .group::-webkit-scrollbar-thumb {
      background-color: #fff;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    .group {
      width: 170px;
      overflow-y: scroll;

      h3 {
        font-size: 12px;
        color: #fff;
        text-align: center;
        margin-bottom: 6px;
      }

      .msg {
        display: flex;
        width: 155px;
        height: 28px;

        background: rgba($color: #fff, $alpha: 0.23);
        border-radius: 2px;
        margin-bottom: 6px;
        margin-left: 5px;
        align-items: center;

        .circle {
          width: 6px;
          height: 6px;
          background: #ff0000;

          border-radius: 50%;
          margin-left: 5px;
        }

        p {
          font-size: 13px;
          color: #ffffff;
          margin-left: 5px;
          width: 100px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }

        .logo {
          margin-right: 5px;
          cursor: pointer;
          width: 20px;
        }
      }
    }
  }

  .send {
    display: flex;
    margin: 0 10px;

    .el-button--success {
      background: transparent;
      margin-left: 10px;
      border: 0;
      height: 30px;
      line-height: 6px;
      margin-left: 20px;
    }
  }

  ::v-deep .inputLoseFocused .el-input__inner {
    background: transparent ;
    height: 30px;
    line-height: 30px;
    border: 0;
  }
  ::v-deep .inputFocused .el-input__inner {
    background: transparent ;
    height: 30px;
    line-height: 30px;
    border: 0;
    color:ghostwhite;
  }
  
}

.closeRoom {
  width: 200px;
  transition: 0.5s;
  transform: translateY(207px);
}

.upload {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

#uploadjl {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  cursor: pointer;
  opacity: 0;
}
</style>
