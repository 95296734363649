<template>
  <div class="index">
    <MainView />
    <Function v-if="type == 1" />
    <Tishi v-if="type == 0" />
    <O2vr />
    <Play :floor="'Rectangle294579029'" :ios="flag" />
    <Client v-if="type == 1" />
    <!-- <Box :touch="flag" /> -->
    <O2im v-if="type == 1" />
    <Box />
    <Zhuchiren />
  </div>
</template>

<script>
import MainView from "../components/MainView.vue";
import Function from "../components/function.vue";
import O2vr from "../components/loading.vue";
import Play from "../components/play_o2report.vue";
import O2im from "../components/o2Im.vue";
import Client from "../components/client.vue";
import Box from "../components/box.vue";

import Tishi from "../components/tishi.vue";
import Zhuchiren from "../components/zhuchiren.vue";
export default {
  data() {
    return {
      flag: false,
      sitFlag: false,
      jushouFlag: false,
      val: 0,
      type: 0,
    };
  },
  components: { MainView, O2vr, Function, Play, O2im, Client, Box, Zhuchiren, Tishi },
  methods: {},
  created() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.flag = true;
    }
    this.Bus.$on("loginError4", () => {
      this.$message.error(this.$t("info.lang.loginError4"));
      setTimeout(() => {
        localStorage.removeItem("info");
        location.reload();
      }, 500);
    });
    // console.log(this.$o2Function.createDrag());
  },
  beforeDestroy(){
    this.Bus.$off();
  },
  mounted() {
    this.Bus.$on("onRoleLoaded", (role) => {
      role.onStateChg.push((state) => {
        if (state == "sit") {
          this.sitFlag = true;
        } else {
          this.sitFlag = false;
        }
      });
    });
    if (document.o2.firstLogin == true) {
      this.val = 5;
    }

    this.type = document.o2.dreamType;
  },
  beforeCreate() {
    if (!localStorage.getItem("info")) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
}
.test {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  display: flex;
  justify-content: center;
  z-index: 9999;
  img {
    width: 50px;
    margin: 0px 10px;
    cursor: pointer;
  }
}
.shadow1 {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #030303, $alpha: 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  .box {
    width: 270px;
    font-size: 18px;

    font-weight: 400;
    text-align: left;
    position: fixed;
    color: #fff;
    height: 220px;
  }
  .box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      margin: 0 60px;
    }
  }
}
</style>
