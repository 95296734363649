<template>
  <div>
    <audio :src="src" id="start"></audio>
    <audio :src="src1" id="start1"></audio>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="tishi" @click="start" v-if="show">
        <img src="../assets/newImg/bottomImg.png" class="bottomImg" />
        <transition enter-active-class="animated fadeIn">
          <div class="msg1">
            <p>这里是进化光环的内部会议厅，在这里，你可以和大家自由的沟通交流!</p>
            <img class="close" src="../assets/newImg/close.png" @click.stop="close" />
          </div>
        </transition>
      </div>
    </transition>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="tishi" v-if="show1">
        <img src="../assets/newImg/bottomImg.png" class="bottomImg" />
        <transition enter-active-class="animated fadeIn">
          <div class="msg1">
            <p>
              前往一楼浏览宣传展板，再继续前进进入大厅，入座欣赏宣传视频，体验不一样的元宇宙视听享受
            </p>
            <img class="close" src="../assets/newImg/close.png" @click.stop="close" />
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import vcircle from "./circle.vue";
export default {
  data() {
    return {
      step: 0,
      translate: 0,
      src: require("../assets/audio/shinei1.mp3"),
      src1: require("../assets/audio/shinei2.mp3"),
      yaogan: false,
      show: true,
      show1: false,
    };
  },
  components: {
    vcircle,
  },
  methods: {
    start() {
      document.getElementById("start").play();
    },

    close() {
      this.show = false;
      this.show1 = false;
      document.getElementById("start").pause();
      document.getElementById("start1").pause();
    },
  },
  mounted() {
    this.Bus.$on("梦界空间点击", (e) => {
      if (e == "tuzi2") {
        console.log("tuzi");
        this.show1 = true;
        this.close();
        setTimeout(() => {
          this.show1 = true;
          document.getElementById("start1").play();
        }, 500);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.loginout {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.tishi {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 700px;
  display: flex;

  align-items: center;

  height: 250px;
}
.bottomImg {
  margin-right: 40px;
  width: 106px;
}

.msg {
  background: url("../assets/newImg/duihua.png");
  width: 539px;
  height: 234px;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  color: #fff;
  position: relative;

  h4 {
    font-size: 21px;
    margin: 40px 0 0 20px;
  }

  p {
    font-size: 16px;
    width: 487px;
    height: 100px;
    overflow-y: scroll;
    margin-top: 17px;
    margin-left: 20px;
  }

  p::-webkit-scrollbar {
    display: none;
  }
}

.msg1 {
  background: url("../assets/newImg/duihua1.png");
  background-size: 100% 100%;
  width: 539px;
  height: 125px;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  position: relative;
  p {
    margin-top: 40px;
    margin-left: 20px;
    color: #ffffff;
    width: 487px;
  }
}
.msg::-webkit-scrollbar {
  display: none;
}

.showBox {
  width: 1156px;
  height: 302px;
  background: url("../assets/newImg/showbox.png");
  position: fixed;
  left: 0;
}

.close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 11px;
  top: 10px;
  cursor: pointer;
  color: #ffffff;
}

.jinru,
.fanhui {
  width: 112px;
  height: 39px;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jinru {
  background: url("../assets/newImg/jinru.png");
  left: 269px;
  bottom: 18px;
}

.fanhui {
  background: url("../assets/newImg/fanhui.png");
  left: 395px;
  bottom: 18px;
  color: #666;
}

.toast {
  width: 402px;
  height: 120px;
  background: url("../assets/newImg/toast.png");
  font-size: 22px;
  color: #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.caozuo,
.caozuo1 {
  width: 418px;
  height: 361px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: url("../assets/newImg/caozuo1.png");

  .caozuoButton {
    width: 107px;
    height: 45px;
    background: #1f2c3a;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }
}

.caozuo1 {
  background: url("../assets/newImg/caozuo0.png");
  background-size: 100% 100%;
  width: 455px;
}

@media screen and (max-width: 1000px) {
  .loginout {
    transform: scale(0.5);
    opacity: 0.6;
    right: -25px;
    top: -0px;
  }
  .bottomImg {
    margin-right: 10px;
    width: 66px;
  }

  .msg1 {
    width: 422.33px;
    height: 90px;
    background-size: 100% 100%;

    p {
      width: 379px;
      height: 28px;
      font-size: 12px;
      margin-top: 25px;
    }
  }

  .close {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 9px;
    top: 8px;
  }

  .tishi {
    height: 175px;
    width: 498px;
    left: -36px;
    /* right: 0; */
    /* margin: auto; */
    transform: scale(0.7);
    bottom: -17px;
  }

  .msg {
    background-size: 100% 100%;
    width: 422.33px;
    height: 175px;

    h4 {
      font-size: 16px;
      margin: 25px 0 0 20px;
    }

    p {
      font-size: 12px;

      width: 376.35px;
      height: 75px;
      margin-top: 15px;
      text-align: justify;
    }

    .jinru,
    .fanhui {
      transform: scale(0.7);
      bottom: 5px;
    }

    .jinru {
      left: 230px;
    }
    .fanhui {
      left: 313px;
    }
  }

  .toast {
    width: 300px;
    height: 92px;
    background-size: 100% 100%;
    font-size: 16px;
    top: -150px;
  }
  .caozuo1 {
    background: url("../assets/newImg/caozuo3.png");
    width: 264.7px;
    height: 232.51px;
    background-size: 100% 100%;
    .caozuoButton {
      transform: scale(0.7);
      right: 72px;
    }
  }

  .caozuo {
    background: url("../assets/newImg/caozuo4.png");
    width: 264.7px;
    height: 232.51px;
    background-size: 100% 100%;
    .caozuoButton {
      transform: scale(0.7);
      right: 72px;
    }
  }
}
</style>
