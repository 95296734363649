<template>
    <div>
        <div class="mailInfo">
            <div class="service_description shadow" id="description_feedback">
                <div class="close_btn" @click="closeClick(false)"></div>
                <div class="description_texts">
                    <h2>
                        お問い合わせ
                    </h2>
                    <div>
                        <h3>会社名</h3>
                        <input type="text" class="feedback-input input-company" @focus.stop="keyFlag(true)"
                            @blur="keyFlag(false)" ref="input" @click.stop="" />
                    </div>
                    <div>
                        <h3>所属部署</h3>
                        <input type="text" class="feedback-input input-department" @focus.stop="keyFlag(true)"
                            @blur="keyFlag(false)" ref="input" @click.stop="" />
                    </div>
                    <div>
                        <h3>役職</h3>
                        <input type="text" class="feedback-input input-position" @focus.stop="keyFlag(true)"
                            @blur="keyFlag(false)" ref="input" @click.stop="" />
                    </div>
                    <div>
                        <h3>お名前</h3>
                        <input type="text" class="feedback-input input-name" @focus.stop="keyFlag(true)"
                            @blur="keyFlag(false)" ref="input" @click.stop="" />
                    </div>
                    <div>
                        <h3>電話番号</h3>
                        <input type="text" class="feedback-input input-mobile" @focus.stop="keyFlag(true)"
                            @blur="keyFlag(false)" ref="input" @click.stop="" />
                    </div>
                    <div>
                        <h3>メールアドレス</h3>
                        <input type="text" class="feedback-input  input-email" @focus.stop="keyFlag(true)"
                            @blur="keyFlag(false)" ref="input" @click.stop="" />
                    </div>
                    <div>
                        <h3>お問合せ内容</h3>
                        <textarea rows="5" class="feedback-input feedback-content input-content" @focus.stop="keyFlag(true)"
                            @blur="keyFlag(false)" ref="input" @click.stop=""></textarea>
                    </div>
                </div>
                <div class="description_btns normal">
                    <div class="feedback-btn" @click="sendClick()">送信</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get_mailTmp, mail_inquiry } from "../assets/api/index";
export default {
    data() {
        return {
            email_to: "",
            email_title: "",
            email_content: "",
        }
    },
    methods: {
        closeClick(e) {
            this.Bus.$emit("onCloseMail", e);
        },
        keyFlag(e) {
            this.Bus.$emit("keyFlag", e);
        },
        sendClick() {
            var company = $('.input-company').val();
            var department = $('.input-department').val();
            var position = $('.input-position').val();
            var name = $('.input-name').val();
            var mobile = $('.input-mobile').val();
            var email = $('.input-email').val();
            var content = $('.input-content').val();
            var error = false;
            if (!company) {
                error = true;
                $('.input-company').addClass('error');
            }
            if (!department) {
                error = true;
                $('.input-department').addClass('error');
            }
            if (!position) {
                error = true;
                $('.input-position').addClass('error');
            }
            if (!name) {
                error = true;
                $('.input-name').addClass('error');
            }
            if (!mobile) {
                error = true;
                $('.input-mobile').addClass('error');
            }
            if (!email) {
                error = true;
                $('.input-email').addClass('error');
            }
            if (!content) {
                error = true;
                $('.input-content').addClass('error');
            }

            if (error) {
                this.$message.error('未入力項目があります。');
                return false;
            }
            this.email_title = this.email_title.replace('${company}', company);
            this.email_title = this.email_title.replace('${department}', department);
            this.email_title = this.email_title.replace('${position}', position);
            this.email_title = this.email_title.replace('${name}', name);
            this.email_title = this.email_title.replace('${mobile}', mobile);
            this.email_title = this.email_title.replace('${email}', email);
            this.email_title = this.email_title.replace('${content}', content);

            this.email_content = this.email_content.replace('${company}', company);
            this.email_content = this.email_content.replace('${department}', department);
            this.email_content = this.email_content.replace('${position}', position);
            this.email_content = this.email_content.replace('${name}', name);
            this.email_content = this.email_content.replace('${mobile}', mobile);
            this.email_content = this.email_content.replace('${email}', email);
            this.email_content = this.email_content.replace('${content}', content);

            var mm = this.$message({
                            message: '送信中です．．．',
                            type: 'info',
                            duration: 15000
                        })
            mail_inquiry({
                email_to: this.email_to,
                email_title: this.email_title,
                email_content: this.email_content,
            }).then((res) => {
                mm.close();
                if (res.code != 1) {
                    this.$message({
                        message:  this.$t("info.lang.mailSendSuccess"),
                        type: "success",
                    });
                } else {
                    this.$message.error(this.$t("info.lang.mailSendError"));
                }
            });

            // $('.input-company').val('');
            // $('.input-department').val('');
            // $('.input-position').val('');
            // $('.input-name').val('');
            // $('.input-mobile').val('');
            // $('.input-email').val('');
            // $('.input-content').val('');
            $('#description_feedback').hide();
            $('#description_feedback').parent().removeClass('show').hide();
        }
    },
    beforeCreate() {
        get_mailTmp().then((res) => {
            console.log(res);
            this.email_to = res[0].value;
            this.email_title = res[1].value;
            this.email_content = res[2].value;
        });
    }
}
</script>

<style lang="scss" scoped>
.shadow {
    top: 0;
    right: 0;
    background: rgba($color: white, $alpha: 0.8);
}

.service_description {
    padding: 20px 20px 0;
    position: relative;
    width: 20%;
    height: 100%;
    float: right;
    right: 5%;
    display: flex;
    flex-direction: column;
}

.close_btn {
    display: block;
    position: relative;
    width: 5%;
    height: 5%;
    margin-top: 10px;
    cursor: pointer;
}

.close_btn::before,
.close_btn::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 40px;
    background: #41719c;
}

.close_btn::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.close_btn::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.description_texts {
    // overflow-y: scroll;
    height: 100%;
}

.service_description h2 {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 25px;
    color: #41719c;
}

.description_texts>div>h3 {
    font-weight: bold;
    font-size: 15px;
    color: #41719c;
    text-align: left;
}

.description_texts>div>div {
    margin-top: 12px;
    margin-left: 24px;
}

.description_texts>div>div>ul {
    margin-top: 12px;
    margin-left: 16px;
    list-style-type: disc;
}

.description_texts>div>div>ul.with_number {
    list-style: none;
}

.description_texts>div>div>ul.with_number>li {
    margin-bottom: 52px;
}

.description_texts>div>div>ul.with_number>li>h4 {
    margin-bottom: 8px;
    font-weight: bold;
}

.description_texts>div>div>ul.with_number>li>p {
    margin-left: 20px;
}

.description_texts>div>div>ul>li {
    margin-bottom: 12px;
}

.description_texts>div>div>ul>li:last-of-type {
    margin-bottom: 0;
}

.service_description h2 span {
    font-size: 16px;
    margin-bottom: 12px;
    display: block;
}

.description_btns {
    display: flex;
    justify-content: right;
    position: relative;
    padding: 10px 0px 10px;
}

.description_texts .feedback-input {
    border: 1px solid #41719c;
    border-radius: 10px;
    padding: 10px 10px;
    width: 90%;
}

.description_texts .feedback-content {
    border: 1px solid #41719c;
    border-radius: 10px;
    padding: 10px 10px;
    width: 90%;
}

.description_texts .feedback-input.error {
    border: 1px solid red;
}

#description_feedback .description_texts div {
    margin: 5px 40px;
}

.feedback-btn {
    align-items: center;
    gap: 10px;
    border: 1px solid #41719c;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    background: #2f5597;
    color:white;
}
</style>